import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { fetchAuthSession } from 'aws-amplify/auth';
import { catchError, switchMap } from 'rxjs/operators';
import { InitializationService } from 'src/app/shared/services/initialization.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private initializationService: InitializationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.shouldAddToken(request.url)) {
      return next.handle(request);
    }

    let withAuthRequest: HttpRequest<unknown>;

    /* Spring already has a JWT for this user */
    if (this.initializationService.getReceivedJwt()) {
      withAuthRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.initializationService.getReceivedJwt()}`,
        },
      });
      return next.handle(withAuthRequest);
    }

    /*
     *  If there is no JWT coming from the backend, append the cognito JWT
     *  to the request and send it to spring to receive a token and a refresh token
     */
    return from(fetchAuthSession()).pipe(
      switchMap(auth => {
        if (auth.tokens) {
          withAuthRequest = request.clone({
            setHeaders: {
              Authorization: `Bearer ${auth.tokens?.idToken}`,
            },
          });
          return next.handle(withAuthRequest);
        } else {
          return next.handle(request);
        }
      }),
      catchError(error => {
        switch (error) {
          case 'No current user':
            /*
             * If auth.currentUser returned null because user does not exist
             * send the request without the token.
             * This is specific to APIs with no authentication
             */
            return next.handle(request);
          default:
            /*
             * If we have current user send request with token.
             * This is specific to api that require authentication from cognito.
             * such as create user api and check email before creating sso user
             */
            return next.handle(withAuthRequest);
        }
      })
    );
  }

  shouldAddToken(requestUrl: string): boolean {
    return (
      requestUrl.includes(environment.serverUrl) &&
      !requestUrl.includes('/refresh') &&
      !requestUrl.includes('/account/invite/user') &&
      !requestUrl.includes('/account/companyinvite/') &&
      !requestUrl.includes('/account/resetpassword') &&
      !requestUrl.includes('/account/userresetaccount/')
    );
  }
}
